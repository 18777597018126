
import { Options, Vue } from "vue-property-decorator";
import GuestLayout from "@/layout/GuestLayout.vue";
import SchoolLogo from "@/components/Avatars/SchoolLogo.vue";

@Options({
  components: { GuestLayout, SchoolLogo },
})
export default class RegisterDone extends Vue {
  private goHome() {
    this.$router.push("/login");
  }

  private get drivingSchoolId() {
    return this.$route.params?.drivingSchool;
  }
}
